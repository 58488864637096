import store from "../store";
import { axiosIns, axiosInsWheel } from "../../helpers/axios/index";
import { onlyAdmins, operatorPass } from "../../router/guards/roles";
import { requestSuccess } from "../helpers/requestSuccess";
import { formatUsers } from "../helpers/formatUsers";

export const state = {
  user: {
    id: null,
    userName: null,
    name: null,
    lastName: null,
    email: null,
    role: null,
    tokenWallet: null,
    client: null,
    operator: null,
  },
  tokenSession: null,
  role: null,
  adminToken: '',
  onlyAdmins,
  operatorPass,
  success: false,
  users: [],
  overlayLoader: false
};

export const getters = {
  getUserData() {
    return state.user;
  },
  getUserName(state) {
    return state.user.userName;
  },
  getToken(state) {
    return state.tokenSession;
  },
  getUserRole(state) {
    return state.user.role;
  },
  getRole(state) {
    return state.role;
  },
  getAminToken() {
    return state.adminToken
  },
  isAdmin(state) {
    return state.onlyAdmins.includes(state.role)
  },
  isOperator(state) {
    return state.operatorPass.includes(state.role)
  },
  getSuccess(state) {
    return state.success
  },
  getUsers(state) {
    return state.users
  },
  getOverlayLoader() {
    return state.overlayLoader
  }
};

export const mutations = {
  setUserMut(state, payload) {
    state.user = payload;
  },

  setToken(state, token) {
    state.tokenSession = token;
  },

  logout(state) {
    localStorage.removeItem("user");
    localStorage.removeItem("tokenSession");
    localStorage.removeItem("tokenWallet");

    (state.user = {
      id: null,
      userName: null,
      name: null,
      lastName: null,
      email: null,
      role: null,
      tokenWallet: null,
    }),
      (state.tokenSession = null);
  },

  setRole(state, role) {
    state.role = role;
  },
  setUser(state, payload) {
    state.user = payload;
  },
  setAdminToken(state, payload) {
    state.adminToken = payload;
  },
  updateTokenWheel(_, payload) {
    axiosInsWheel.defaults.headers.common["authentication-x-token"] = payload;
  },
  setSuccess(state, payload) {
    state.success = payload
  },
  setUsers(state, payload) {
    state.users = payload
  },
  toggleOverlayLoader(state) {
    state.overlayLoader = !state.overlayLoader
  }
};

export const actions = {
  async setUserAct({ commit }, idUser) {
    // const data = await (await axiosIns.get("users")).data.users[1];
    const data = await (await axiosIns.get(`users/${idUser}`)).data;
    commit("setUserMut", data.user);
    return data.user;
  },
  async checkAutentication({ commit }) {
    const token = localStorage.getItem("tokenSession");

    if (!token) {
      commit("logout");
      return;
    }

    const { data } = await axiosIns.post(`/auth/lookup`);

    commit("setUserMut", data.user);
    commit("setToken", data.token);
    return data;
  },
  async fetchUserData({ commit }, { body }) {
    try {
      const { data } = await axiosIns.post("/auth/lookup", body);
      if (!data.user) return commit("setUserMut", {})
      const formatted = { ...data.user, rol: data.user.role.name, role: { name: data.user.rol ? data.user.rol : data.user.role.name } };
      const user = { user: formatted }
      localStorage.setItem("user", JSON.stringify(user));
      commit("setUserMut", formatted)
    } catch (error) {
      console.log('ERROR FETCH USER DATA', error);
    }
  },
  async fetchUsers({ commit }, filter) {
    try {
      const { data: rouletteApi } = await axiosIns.get("/users/reports", {
        params: {
          ...filter,
        }
      })

      let adminData = []

      try {
        const { data, status } = await axiosInsWheel.get("/user")

        if (status > 201) adminData = []
        else adminData = data.users

      } catch (error) {
        console.log('ERROR FETCHING USERS WHEEL', error);
        adminData = []
      }

      commit('setUsers', formatUsers(rouletteApi.users, adminData))
    } catch (error) {
      console.log('error fetching users', error);
      commit('setUsers', [])
    }
  },
  async create({ commit }, doc) {
    try {
      const dataAdmin = axiosInsWheel.post('auth/register', { ...doc, client: doc.name })
      const dataRoulette = axiosIns.post("users", doc)

      const data = await Promise.allSettled([dataAdmin, dataRoulette])

      const { success, errors } = requestSuccess(...data)

      if (!success) return commit('setSuccess', false)

      store.commit("notification/setErrors", errors);
      commit('setSuccess', true)
    } catch (error) {
      console.log('ERROR CREATING USER', error);
      commit('setSuccess', false)
    }
  },
  async update({ commit }, doc) {
    try {

      if (!doc.uuid) {
        try {
          await axiosInsWheel.post('auth/register', {
            ...doc,
            client: doc.userClient[0].name,
            rol: doc.userRole[0].name,
            password: doc.userName
          })
        } catch (error) {
          console.log('ERROR CREATING USER WHEEL', error);
        }
      }

      const { status } = await axiosIns.put(`users/${doc._id}`, doc)

      if (status > 201) return commit('setSuccess', false)

      commit('setSuccess', true)
    } catch (error) {
      console.log('ERROR UPDATING USER', error);
      commit('setSuccess', false)
    }
  },
};
