export const rouletteModules = [
  {
    id: 1,
    jackpost: [50],
  },
  {
    id: 2,
    jackpost: [50, 50],
  },
  {
    id: 3,
    jackpost: [50, 50, 50],
  },
  {
    id: 4,
    jackpost: [50, 50, 50, 50],
  },
  {
    id: 5,
    jackpost: [50, 50, 50, 50, 50],
  },
  {
    id: 6,
    jackpost: [50, 50, 50, 50, 100],
  },
  {
    id: 7,
    jackpost: [50, 50, 50, 100, 100],
  },
  {
    id: 8,
    jackpost: [50, 150, 150, 50],
  },
  {
    id: 9,
    jackpost: [50, 150, 150, 50],
  },
  {
    id: 10,
    jackpost: [50, 50, 200, 200],
  },
  {
    id: 11,
    jackpost: [50, 50, 50, 200, 200],
  },
  {
    id: 12,
    jackpost: [50, 50, 50, 150, 300],
  },
  {
    id: 13,
    jackpost: [50, 50, 50, 150, 350],
  },
  {
    id: 14,
    jackpost: [350, 350],
  },
  {
    id: 15,
    jackpost: [350, 400],
  },
  {
    id: 16,
    jackpost: [300, 500],
  },
  {
    id: 17,
    jackpost: [300, 550],
  },
  {
    id: 18,
    jackpost: [50, 50, 150, 650],
  },
  {
    id: 19,
    jackpost: [50, 50, 50, 150, 650],
  },
  {
    id: 20,
    jackpost: [500, 500],
  },

  {
    id: 21,
    jackpost: [100],
  },
  {
    id: 22,
    jackpost: [100, 50],
  },
  {
    id: 23,
    jackpost: [100, 100],
  },
  {
    id: 24,
    jackpost: [50, 100, 100],
  },
  {
    id: 25,
    jackpost: [50, 50, 100, 100],
  },
  {
    id: 26,
    jackpost: [50, 100, 100, 100],
  },
  {
    id: 27,
    jackpost: [100, 100, 100, 100],
  },
  {
    id: 28,
    jackpost: [50, 100, 200, 50, 50],
  },
  {
    id: 29,
    jackpost: [250, 250],
  },
  {
    id: 30,
    jackpost: [50, 50, 50, 150, 250],
  },
  {
    id: 31,
    jackpost: [300, 300],
  },
  {
    id: 32,
    jackpost: [50, 150, 450],
  },
  {
    id: 33,
    jackpost: [150, 50, 50, 150, 400],
  },
  {
    id: 34,
    jackpost: [150, 50, 50, 150, 450],
  },
  {
    id: 35,
    jackpost: [350, 450],
  },
  {
    id: 36,
    jackpost: [350, 500],
  },
  {
    id: 37,
    jackpost: [150, 50, 50, 150, 600],
  },
  {
    id: 38,
    jackpost: [450, 500],
  },
  {
    id: 39,
    jackpost: [1000],
  },
  {
    id: 40,
    jackpost: [150],
  },
  {
    id: 41,
    jackpost: [50, 150],
  },
  {
    id: 42,
    jackpost: [150, 100],
  },
  {
    id: 43,
    jackpost: [50, 50, 50, 150],
  },
  {
    id: 44,
    jackpost: [50, 150, 150],
  },
  {
    id: 45,
    jackpost: [150, 250],
  },
  {
    id: 46,
    jackpost: [50, 200, 200],
  },
  {
    id: 47,
    jackpost: [200, 300],
  },
  {
    id: 48,
    jackpost: [50, 50, 150, 300],
  },
  {
    id: 49,
    jackpost: [50, 50, 150, 350],
  },
  {
    id: 50,
    jackpost: [300, 350],
  },
  {
    id: 51,
    jackpost: [300, 400],
  },
  {
    id: 52,
    jackpost: [50, 150, 550],
  },
  {
    id: 53,
    jackpost: [400, 400],
  },
  {
    id: 54,
    jackpost: [400, 450],
  },
  {
    id: 55,
    jackpost: [200],
  },
  {
    id: 56,
    jackpost: [250],
  },
  {
    id: 57,
    jackpost: [100, 200],
  },
  {
    id: 58,
    jackpost: [50, 50, 50, 200],
  },
  {
    id: 59,
    jackpost: [50, 50, 50, 250],
  },
  {
    id: 60,
    jackpost: [100, 100, 250],
  },
  {
    id: 61,
    jackpost: [50, 200, 250],
  },
  {
    id: 62,
    jackpost: [50, 150, 350],
  },
  {
    id: 63,
    jackpost: [250, 350],
  },
  {
    id: 64,
    jackpost: [250, 400],
  },
  {
    id: 65,
    jackpost: [50, 50, 150, 450],
  },
  {
    id: 66,
    jackpost: [50, 50, 150, 500],
  },
  {
    id: 67,
    jackpost: [50, 50, 50, 150, 500],
  },
  {
    id: 68,
    jackpost: [50, 50, 50, 150, 550],
  },
  {
    id: 69,
    jackpost: [150, 150],
  },
  {
    id: 70,
    jackpost: [50, 300],
  },
  {
    id: 71,
    jackpost: [200, 200],
  },
  {
    id: 72,
    jackpost: [200, 350],
  },
  {
    id: 73,
    jackpost: [50, 150, 400],
  },
  {
    id: 74,
    jackpost: [50, 50, 150, 400],
  },
  {
    id: 75,
    jackpost: [50, 150, 500],
  },
  {
    id: 76,
    jackpost: [50, 50, 150, 550],
  },
  {
    id: 77,
    jackpost: [50, 150, 650],
  },
  {
    id: 78,
    jackpost: [50, 250],
  },
  {
    id: 79,
    jackpost: [350],
  },
  {
    id: 80,
    jackpost: [50, 50, 100, 200],
  },
  {
    id: 81,
    jackpost: [100, 150, 200],
  },
  {
    id: 82,
    jackpost: [150, 350],
  },
  {
    id: 83,
    jackpost: [50, 250, 250],
  },
  {
    id: 84,
    jackpost: [100, 100, 100, 100, 200],
  },
  {
    id: 85,
    jackpost: [50, 600],
  },
  {
    id: 86,
    jackpost: [50, 650],
  },
  {
    id: 87,
    jackpost: [50, 150, 600],
  },
  {
    id: 88,
    jackpost: [50, 50, 150, 600],
  },
  {
    id: 89,
    jackpost: [300],
  },
  {
    id: 90,
    jackpost: [50, 300],
  },
  {
    id: 91,
    jackpost: [50, 150, 250],
  },
  {
    id: 92,
    jackpost: [50, 450],
  },
  {
    id: 93,
    jackpost: [50, 50, 50, 200, 200],
  },
  {
    id: 94,
    jackpost: [50, 50, 250, 250],
  },
  {
    id: 95,
    jackpost: [200, 200, 250],
  },
  {
    id: 96,
    jackpost: [400],
  },
  {
    id: 97,
    jackpost: [100, 350],
  },
  {
    id: 98,
    jackpost: [100, 100, 100, 100, 100],
  },
  {
    id: 99,
    jackpost: [50, 500],
  },
  {
    id: 100,
    jackpost: [50, 50, 150, 150, 200],
  },
  {
    id: 101,
    jackpost: [50, 50, 350],
  },
  {
    id: 102,
    jackpost: [100, 150, 250],
  },
  {
    id: 103,
    jackpost: [150, 400],
  },
  {
    id: 104,
    jackpost: [50, 550],
  },
  {
    id: 105,
    jackpost: [50, 400],
  },
];