import axiosWheel from "../../helpers/axios/wheelApi";
import i18n from '../../i18n'
import { phases } from "../helpers/phases";

export const state = {
  wheels: [],
  wheelsTable: [
    {
      key: "name",
      sortable: true,
      label: "NOMBRE",
    },
    {
      key: "logo",
      label: "LOGO",
    },
    {
      key: "providerId",
      label: "PROVIDER ID",
    },
    {
      key: "manualDisable",
      label: i18n.t('tableReports.status').toUpperCase(),
      formatter: (item) => !item ? 'ACTIVO' : 'INACTIVO'
    },
    {
      key: "openingTime",
      sortable: false,
      label: "APERTURA",
    },
    {
      key: "closingTime",
      sortable: false,
      label: "CIERRE",
    },
    {
      key: "actions",
      label: "ACCIONES",
    },
  ],
  success: false,
  player: {},
  chips: [],
  currency: {},
  providers: [],
  phases,
  game: {},
  phase: null
}
export const getters = {
  getWheels() {
    return state.wheels
  },
  getTableHeaders(state) {
    return state.wheelsTable
  },
  getSuccess(state) {
    return state.success
  },
  getPlayer(state) {
    return state.player
  },
  getChips(state) {
    return state.chips
  },
  getCurrency(state) {
    return state.currency
  },
  getProviders(state) {
    return state.providers
  },
  getCurrent(state) {
    return state.game
  },
  getGamesByProvider(state) {
    return state.providers.map(provider => {
      const wheels = state.wheels.filter(game => game.providerId === provider).map(game => game.name)
      return {
        games: wheels.join(', '),
        provider
      }
    })
  },
  getPhases(state) {
    return Object.keys(state.phases).map(key => {
      return {
        name: i18n.t(`phases.${key}`),
        key
      }
    })
  },
  getPhase() {
    return state.phase
  }
}
export const mutations = {
  setWheels(state, payload) {
    state.wheels = payload;
  },
  setChips(state, payload) {
    state.chips = payload;
  },
  setPlayer(state, payload) {
    state.player = payload;
  },
  setSuccess(state, payload) {
    state.success = payload;
  },
  setCurrency(state, payload) {
    state.currency = payload;
  },
  setProviders(state, payload) {
    state.providers = payload;
  },
  setGame(state, payload) {
    state.game = payload
  },
  setPhase(state, payload) {
    state.phase = payload
  }
}
export const actions = {
  async fetchWheels({ commit }) {
    try {
      const { data, status } = await axiosWheel.get("/game/all");

      if (status > 200) return commit("setWheels", []);

      commit("setWheels", data.games);
    } catch (error) {
      console.log('ERROR FETCH WHEELS', error);
    }
  },
  async getOne({ commit }, uuid) {
    try {
      const { data, status } = await axiosWheel.get(`/game/get-one/${uuid}`)

      if (status > 201) return commit('setGame', {})

      commit('setGame', data.game)
    } catch (error) {
      console.log('ERROR GET ONE WHEEL', error);
      commit('setGame', {})
    }
  },
  async update({ commit }, doc) {

    const { uuid } = doc;

    try {
      const { status } = await axiosWheel.patch(`/wheel/${uuid}`, doc)

      if (status > 201) throw new Error('Error updating wheel')

      commit('setSuccess', true)
    } catch (error) {
      commit('setSuccess', false)
      throw new Error('Error upadte wheel', error)
    }
  },
  async create({ commit }, doc) {
    try {
      const { status } = await axiosWheel.post('/wheel', doc)

      if (status > 201) throw new Error('Error creating wheel')

      commit('setSuccess', true)
    } catch (error) {
      commit('setSuccess', false)
      throw new Error('Error upadte wheel', error)
    }
  },
  async remove({ commit }, uuid) {

    try {
      const { status } = await axiosWheel.delete(`/wheel/${uuid}`)

      if (status > 201) throw new Error('Error deleting wheel')

      commit('setSuccess', true)
    } catch (error) {
      commit('setSuccess', false)
      throw new Error('Error upadte wheel', error)
    }
  },
  async setLaunch({ commit }, item) {
    try {
      const { data, status } = await axiosWheel.post('launch', item)

      if (status > 201) return commit('setSuccess', false)

      const { chips, player, currency } = data
      commit('setSuccess', true)
      commit('setPlayer', player)
      commit('setCurrency', currency)
      commit('setChips', chips.map(chip => ({ ...chip, number: chip.value })))
    } catch (error) {
      console.log('ERROR SET LAUNCH -> WHEEL MODULE', error);
      commit('setSuccess', false)
    }
  },
  async fetchProviders({ commit }) {
    try {
      const { data, status } = await axiosWheel.get('wheel/providers')

      if (status > 200) return commit('setProviders', [])

      commit('setProviders', data.providers)
    } catch (error) {
      console.log('ERROR FETCHIN PROVIDERS', error);
      commit('setProviders', [])
    }
  },
  async changePhase({ commit }, { providerId, phase }) {

    try {

      const { status, data } = await axiosWheel.patch('round/change-phase-by-table', {
        phase,
        providerId
      })

      if (status > 201) return commit('setSuccess', false)

      commit('setSuccess', true)
      commit('setPhase', data.phase)
    } catch (error) {
      console.log('ERROR CHANGE PHASE', error);
      commit('setSuccess', false)
    }
  },
  async manualDisable({ commit }, uuid) {
    try {

      const { status } = await axiosWheel.patch(`wheel/${uuid}/manual-disabled-game`)

      if (status > 201) throw new Error('ERROR UPDATING WHEEl')

      commit('setSuccess', true)
    } catch (error) {
      console.log('ERROR MANUAL DISABLE WHEEL', error);
      commit('setSuccess', false)
    }
  }
}