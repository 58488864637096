import axios from "axios";
const API_URL = process.env.VUE_APP_API;
const axiosIns = axios.create({
  headers: {
    "Content-type": "application/json; charset=utf-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Content-Type",
    "cache-control": "no-cache",
  },
  // You can add your headers here
  // ================================
  baseURL: API_URL,
  // timeout: 1000,

  validateStatus: function (status) {
    return status >= 200 && status < 500; // defecto
  },
});
let user = JSON.parse(localStorage.getItem("user"));
let token = localStorage.getItem('tokenSession')
if (user) {
  axiosIns.defaults.headers.common["Authorization"] = "Bearer " + user.token;
}
if (token) {
  axiosIns.defaults.headers.common["x-token"] = token;
  axiosIns.defaults.headers.common["authentication-x-token"] = token;
}

export default axiosIns;
