import { axiosIns } from "../../helpers/axios/index";

export const state = {
  success: false,
  round: null
}

export const getters = {
  isSuccess() {
    return state.success
  }
}

export const mutations = {
  setSuccess(state, payload) {
    state.success = payload;
  },
  setRound(state, payload) {
    state.round = payload
  }
}

export const actions = {
  async closeRound({ commit }, { ID_Ronda, ID_Ruleta, Resultado }) {
    try {

      const result = {
        ID_Ronda,
        ID_Ruleta,
        Resultado,
        Giro: 'AH',
        Rpm: '18',
        Error: '0',
        Fecha: new Date().toDateString(),
      }

      const { data } = await axiosIns.put(`/round/end`, result);

      if (!data.ok) return commit("setSuccess", false)

      commit("setSuccess", true)
    } catch (error) {
      console.log('ERROR CLOSE ROUND', error);
      commit("setSuccess", false)
    }
  }
}