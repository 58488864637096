export const state = {
  isThereAnInterval: false,
  limit: 2, //Equivale a 3 vueltas de 5 minutos
};

export const getters = {
  getInterval() {
    return state.isThereAnInterval;
  },
  getLimit() {
    return state.limit;
  },
};

export const mutations = {
  setAnInterval(state, value) {
    state.isThereAnInterval = value;
  },
};
