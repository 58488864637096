import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import router from "./router";

import vco from "v-click-outside";
import VueApexCharts from "vue-apexcharts";
import VueSweetalert2 from "vue-sweetalert2";
import VueSlideBar from "vue-slide-bar";
import Vuelidate from "vuelidate";
import i18n from "./i18n";
import store from "@/state/store";
// AXIOS
import {
  axiosIns,
  axiosInsDragonTiger,
  axiosInsWheel,
  axiosInsBlackJack
} from "@/helpers/axios/index";
// AXIOS
import moment from "moment";
import filters from "./Filters/Filters";
import Vuesax from "vuesax";

import "vuesax/dist/vuesax.css"; //Vuesax styles

import App from "./App.vue";
// As a plugin
import VueMask from "v-mask";
Vue.config.productionTip = false;

import * as VueGoogleMaps from "vue2-google-maps";
import Lightbox from "vue-easy-lightbox";

Vue.use(Lightbox);

// Vuesax
Vue.use(Vuesax, {
  colors: {
    primary: "#42396c",
    success: "#c462b0",
  },
});

Vue.prototype.moment = moment;
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places",
  },
  installComponents: true,
});

// import { initFirebaseBackend } from "./helpers/firebase/authUtils";

// import { configureFakeBackend } from "./helpers/fakebackend/fake-backend";

// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_APIKEY,
//   authDomain: process.env.VUE_APP_AUTHDOMAIN,
//   databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
//   projectId: process.env.VUE_APP_PROJECTId,
//   storageBucket: process.env.VUE_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
//   appId: process.env.VUE_APP_APPId,
//   measurementId: process.env.VUE_APP_MEASUREMENTID,
// };

// if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
//   initFirebaseBackend(firebaseConfig);
// } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
//   configureFakeBackend();
// }

filters.forEach((f) => {
  Vue.filter(f.name, f.execute);
});
import "@/assets/scss/app.scss";

Vue.component("VueSlideBar", VueSlideBar);
Vue.use(BootstrapVue);
Vue.use(vco);
Vue.component("apexchart", VueApexCharts);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);

Vue.use(require("vue-chartist"));
Vue.use(VueMask);

Vue.prototype.$http = axiosIns;
Vue.prototype.$httpDragonTiger = axiosInsDragonTiger;
Vue.prototype.$httpWheel = axiosInsWheel;
Vue.prototype.$httpBlackJack = axiosInsBlackJack;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
