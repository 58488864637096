export const state = {
  actions: [],
  loading: false
};

export const getters = {
  getLoading() {
    return state.loading
  }
}

export const mutations = {
  setActionsMut(state, newValue) {
    state.actions.push(newValue);
  },
  toggleLoading(state) {
    state.loading = !state.loading
  }
};
