import store from '../../state/store'
import { getFirebaseBackend } from '../../helpers/firebase/authUtils';
import {
  axiosInsWheel,
  axiosIns,
  axiosInsBlackJack,
  axiosInsDragonTiger as axiosDragonTiger
} from '../../helpers/axios/index';


export const state = {
  currentUser: sessionStorage.getItem('authUser'),
  success: false,
  token: '',
  adminToken: ''
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
    saveState('auth.currentUser', newValue)
  },
  setSuccess(state, payload) {
    state.success = payload;
  },
  setToken(state, payload) {
    state.token = payload;
  },
  setAdminToken(state, payload) {
    state.adminToken = payload;
  },
  setTokenToApiConfig(_, { axiosInstances, token }) {
    for (let ins in axiosInstances) {
      const axios = axiosInstances[ins]
      axios.defaults.headers.common["x-token"] = token;
      axios.defaults.headers.common["authentication-user-token"] = token;
      axios.defaults.headers.common["authentication-x-token"] = token;
    }
  }
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser
  },
  isSuccess() {
    return state.success
  },
  getToken() {
    return state.token
  },
  getAdminToken() {
    return state.adminToken
  }
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch('validate')
  },

  // Logs in the current user.
  logIn({ commit, dispatch, getters }, { email, password } = {}) {
    if (getters.loggedIn) return dispatch('validate')

    return getFirebaseBackend().loginUser(email, password).then((response) => {
      const user = response
      commit('SET_CURRENT_USER', user)
      return user
    });
  },

  // Logs out the current user.
  logOut({ commit }) {
    // eslint-disable-next-line no-unused-vars
    commit('SET_CURRENT_USER', null)
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-unused-vars
      getFirebaseBackend().logout().then((response) => {
        resolve(true);
      }).catch((error) => {
        reject(this._handleError(error));
      })
    });
  },

  // register the user
  register({ commit, dispatch, getters }, { email, password } = {}) {
    if (getters.loggedIn) return dispatch('validate')

    return getFirebaseBackend().registerUser(email, password).then((response) => {
      const user = response
      commit('SET_CURRENT_USER', user)
      return user
    });
  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  resetPassword({ commit, dispatch, getters }, { email } = {}) {
    if (getters.loggedIn) return dispatch('validate')

    return getFirebaseBackend().forgetPassword(email).then((response) => {
      const message = response.data
      return message
    });
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  // eslint-disable-next-line no-unused-vars
  validate({ commit, state }) {
    if (!state.currentUser) return Promise.resolve(null)
    const user = getFirebaseBackend().getAuthenticatedUser();
    commit('SET_CURRENT_USER', user)
    return user;
  },

  //LOGIN IN ROULETTE N DRAGON API

  async login({ commit }, { userName, password }) {
    try {
      const response = await axiosIns.post("auth/login", {
        userName,
        password,
      })

      if (response.status !== 200) return commit('setSuccess', false);

      const axiosInstances = [axiosIns, axiosDragonTiger, axiosInsWheel, axiosInsBlackJack]
      const { token } = response.data

      commit('setTokenToApiConfig', { axiosInstances, token })

      localStorage.setItem("tokenSession", token);

      store.commit("user/setToken", token);
      commit('setToken', token);
      commit('setSuccess', true);
    } catch (error) {
      console.log('ERORR IN LOGIN', error);
      commit('setSuccess', false);
    }
  },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}