import { axiosIns } from "../../helpers/axios/index";

export const state = {
  players: [],
  success: false,
}
export const getters = {
  getPlayers() {
    return state.players
  },
  getSuccess() {
    return state.success
  }
}
export const mutations = {
  setPlayers(state, payload) {
    state.players = payload;
  },
  setSuccess(state, payload) {
    state.success = payload;
  }
}
export const actions = {
  async fetchPlayerRound({ commit }, { options }) {
    try {
      const { data, status } = await axiosIns.get("/player-round/getPlayer", { ...options });

      if (status > 200) {
        commit("setSuccess", false)
        return commit("setPlayers", []);
      }
      if (data.error) {
        commit("setSuccess", false)
        return commit("setPlayers", []);
      }

      commit("setSuccess", true);
      commit("setPlayers", data.res);
    } catch (error) {
      console.log('ERROR FETCH PLAYER ROUND', error);
    }
  }
}