<template>
  <div id="app " class="bg-soft-secondary">
    <session-expired v-if="expired" @resetCount="resetCount"></session-expired>
    <div v-if="loader" class="loader">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-else>
      <router-view v-if="statusApi && !expired" />
      <Error500 v-if="!statusApi && !expired" />
    </div>
  </div>
</template>

<script>
import appConfig from "@/app.config";
import { notificationMethods } from "@/state/stateHelpers";
import Error500 from "./views/pages/utility/500.vue";
import sessionExpired from "./components/sessionExpired.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  components: {
    Error500,
    sessionExpired,
  },
  computed: {
    ...mapGetters({
      isThereAnInterval: "sessionExpired/getInterval",
      userRole: "user/getUserRole",
      getRole: "user/getRole"
    }),
  },
  data() {
    return {
      statusApi: false,
      expired: false,
      loader: false,
      cont: 0,
      interval: null,
      regex: /^(login|register)$/g,
      role: "",
    };
  },
  methods: {
    clearNotification: notificationMethods.clear,
    ...mapMutations({
      setAnInterval: "sessionExpired/setAnInterval",
    }),
    mouseIn() {
      this.$el.addEventListener("mousemove", () => (this.cont = 0));
    },
    resetCount() {
      this.expired = false;
      this.cont = 0;
    },
    countSetInterval() {


      if (this.role === "CRUPIER") return

      //Contador para verificar la sesion activa
      this.mouseIn();
      let isThereAnInterval = this.$store.getters["sessionExpired/getInterval"];
      const LIMIT = this.$store.getters["sessionExpired/getLimit"];
      const { name, meta } = this.$route;

      if (isThereAnInterval) return; //Si ya hay un temporizador activo evita que se multiplique

      if (!isThereAnInterval && !this.regex.test(name) && meta.authRequired) {
        this.setAnInterval(true);
      }

      if (!isThereAnInterval && !this.regex.test(name) && meta.authRequired) {
        //Si cumple con las validadciones
        this.setAnInterval(true);
        this.interval = setInterval(() => {

          if (this.role === "CRUPIER") return clearInterval(this.interval)

          if (this.cont === LIMIT) {
            this.setAnInterval(false);
            this.expired = true;

            return clearInterval(this.interval);
          }

          this.cont++;
        }, 300000); //Equivalente a 5 minutos en milisegundos
        // 300000
      }
    },
    verifyStatusApi() {
      this.loader = true;
      this.loader = false;
      this.statusApi = true;
      this.$http
        .get("health")
        .then(() => {
          this.loader = false;
          this.statusApi = true;
        })
        .catch(() => {
          this.loader = false;
          this.statusApi = false;
        });
    },

    checkAuth() {
      this.$store.dispatch("user/checkAutentication");
    },

    async getUserRole() {

      const data = await this.getUserData();

      if (!data) return
      const { user: userData } = data;
      const localUser = localStorage.getItem('user')

      this.role = userData.role ? userData.role.name : localUser;
    },
    ...mapActions({
      getUserData: "user/checkAutentication"
    })
  },
  watch: {
    /**
     * Clear the alert message on route change
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // clear alert on location change
      this.clearNotification();
    },
  },
  mounted() {

    this.verifyStatusApi();
    this.countSetInterval();
    // this.checkAuth();
    this.$store.dispatch("lang/getLang");

    this.role = this.getRole;

  },
  updated() {
    this.countSetInterval();

    this.role = this.getRole;

  },

};
</script>

<style>
.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vs-input-content>input {
  width: 100% !important;
}

.shadow-lg {
  border-radius: 40px !important;
}
</style>
