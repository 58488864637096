export const jackpotRouletteFields = [
  {
    label: "ROUND ID",
    key: "round.identifierNumber",
    class: "space-nowrap"
  },
  {
    label: "GAME",
    key: "game.name",
    class: "space-nowrap"
  },
  {
    label: "JACKPOTS",
    key: "jackpots"
  },
  {
    label: "PLAYER",
    key: "bet.player.username"
  },
  {
    label: "PLAYER ID",
    key: "bet.player.userId",
    class: "space-nowrap"
  },
  {
    label: "OPERATOR",
    key: "bet.operator[0].name"
  },
  {
    label: "DEBIT",
    key: "bet.totalAmount"
  },
  {
    label: "CREDIT",
    key: "amount"
  },
  {
    label: "CURRENCY",
    key: "bet.currencies[0].short"
  },
  {
    label: "TRANSACTION ID",
    key: "bet.transactionId",
    class: "space-nowrap"
  },
  {
    label: "DETALLE",
    key: "details"
  },
  {
    label: "FECHA",
    key: "createdAt",
    class: "space-nowrap"
  },
]

export const jackpotWheelFields = [
  {
    label: "ROUND ID",
    key: "round.identifierNumber",
    class: "space-nowrap"
  },
  {
    label: "GAME",
    key: "game.name",
    class: "space-nowrap"
  },
  {
    label: "JACKPOTS",
    key: "jackpots"
  },
  {
    label: "PLAYER",
    key: "player.username"
  },
  {
    label: "PLAYER ID",
    key: "player.userId",
    class: "space-nowrap"
  },
  {
    label: "OPERATOR",
    key: "player.operator.name"
  },
  {
    label: "DEBIT",
    key: "bet.totalAmount"
  },
  {
    label: "CREDIT",
    key: "amount"
  },
  {
    label: "CURRENCY",
    key: "currency.isoCode"
  },
  {
    label: "TRANSACTION ID",
    key: "bet.uuid",
    class: "space-nowrap"
  },
  {
    label: "DETALLE",
    key: "details"
  },
  {
    label: "FECHA",
    key: "createdAt",
    class: "space-nowrap"
  },
]