export const formatUsers = (usersRoulette, usersWheel) => {
  const formatted = []
  for (let i in usersRoulette) {
    const userRoulette = usersRoulette[i]
    if (!userRoulette) continue
    const user = usersWheel.find(u => u.userName === userRoulette.userName)
    if (user) {
      formatted.push({ ...user, ...userRoulette })
    } else {
      formatted.push(userRoulette)
    }
  }
  return formatted
}