import store from "../store";
import { routes } from "../../helpers/axios/apiRoutes";
import { axiosInsDragonTiger, axiosIns, axiosInsWheel } from '../../helpers/axios/index'
import { requestSuccess } from "../helpers/requestSuccess";
import { GamesTypes } from "../helpers";

export const state = {
  currencies: [],
  roulettes: [],
  success: false,
  operator: [],
  clients: [],
  msg: "",
  games: [],
  clientRoulettes: [],
  dragonTigers: [],
  wheels: [],
  client: {}
};

export const getters = {
  getCurrencies() {
    return state.currencies;
  },
  getClientRoulettes() {
    return state.clientRoulettes;
  },
  getRoulettes() {
    return state.roulettes;
  },
  getOperatorsClient() {
    return state.operator;
  },
  isSuccess() {
    return state.success;
  },
  getClients() {
    return state.clients
  },
  getMessage() {
    return state.msg
  },
  getGames() {
    return state.games
  },
  getDragonTigers() {
    return state.dragonTigers
  },
  getWheels() {
    return state.wheels
  },
  getClient() {
    return state.client
  }
};

export const mutations = {
  setCurrencies(state, payload) {
    state.currencies = [];
    state.currencies = payload;
  },
  setClientRoulettes(state, payload) {
    state.clientRoulettes = [];
    state.clientRoulettes = payload;
  },
  setRoulettes(state, payload) {
    state.roulettes = [];
    state.roulettes = payload;
  },
  setSuccess(state, payload) {
    state.success = payload;
  },
  setOperatorsClient(state, payload) {
    state.operator = [];
    state.operator = payload;
  },
  setClients(state, payload) {
    state.clients = []
    state.clients = payload
  },
  setMessage(state, payload) {
    state.msg = "";
    state.msg = payload;
  },
  setGames(state, payload) {
    state.games = []
    state.games = payload
  },
  setDragonTigers(state, payload) {
    state.dragonTigers = [];
    state.dragonTigers = payload;
  },
  setWheels(state, payload) {
    state.wheels = [];
    state.wheels = payload;
  },
  setClient(state, payload) {
    state.client = payload
  }
};

export const actions = {
  async fetchClientCurrencies({ commit }, { path, id }) {
    try {
      const { data } = await axiosIns.get(`${path}/${id}/currencies`);

      if (!data.ok) {
        commit("setCurrencies", []);
        commit("setSuccess", false);
        return false;
      }

      const currencies = data.currencies.map((curr) => curr);

      commit("setCurrencies", currencies);
      commit("setSuccess", true);
      return currencies;
    } catch (err) {
      console.log("Error fetchGameCurrencies", err);
      commit("setCurrencies", []);
      commit("setSuccess", false);
    }
  },
  async addCurrenciesToClient({ commit }, { currencies = [], uuid = '' }) {
    try {
      const queries = currencies.map(async (cr) => {
        return await axiosInsDragonTiger.put(`client/add-currency/${uuid}`, {
          currency: cr.short
        })
      });

      const data = await Promise.allSettled([...queries]);
      const errorMessages = [];
      for (let i = 0; i < data.length; i++) {
        const { status, data: dataCurrency } = data[i].value;
        if (status > 201 && dataCurrency.error) {
          const { name } = currencies[i];
          const message = `${dataCurrency.error} - ${name}`;
          errorMessages.push(message)
        }
      }

      if (!errorMessages.length) return commit('setSuccess', true);

      console.log('errorMessages', errorMessages);
    } catch (error) {
      console.log('ERROR ADD CURRENCIES TO CLIENT', error);
    }
  },
  async removeCurrenciesToClient({ commit }, { uuid = '', currency = '' }) {
    try {
      const { data, status } = await axiosInsDragonTiger.delete(`/client/${uuid}/remove-currency/${currency}`);

      if (status > 201) return commit('setSuccess', false);
      if (data.error) return commit('setSuccess', false);

      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR ADD CURRENCIES TO CLIENT', error);
    }
  },
  async fetchClientRoulettes({ commit }, { id }) {
    try {
      const { data } = await axiosIns.get(`/clients/${id}/roulettes`);
      commit("setClientRoulettes", data.clientRoulettes);
    } catch (error) {
      console.log("ERROR IN fetchClientRoulettes", error);
    }
  },
  async filterRoulette({ commit }, { clientRoulettes, roulettes }) {


    try {

      const gameIds = roulettes.map(game => game._id);

      const clientRoulettesIds = clientRoulettes?.map((cr) => cr._id);

      const difference = gameIds.filter(x => clientRoulettesIds.indexOf(x) === -1);
      const roulettesToAdd = roulettes.filter(roulette => difference.includes(roulette._id));
      commit("setRoulettes", roulettesToAdd);
    } catch (error) {
      console.log("ERROR ADD ROULETTE -> CLIENT MODULE", error);
    }
  },
  async fetchOperatorClients({ commit }, { id }) {
    try {
      const { data } = await axiosIns.get(`/operators?client=${id}`)

      if (!data.ok) return commit("setSuccess", false)

      const operators = data.operators;

      commit("setOperatorsClient", operators)
      commit("setSuccess", true)
    } catch (error) {
      console.log('ERROR FETCH CLIENTS', error);
    }
  },
  async fetchClients({ commit }) {
    try {
      const { data, status } = await axiosIns.get("/clients");

      if (status > 200) return commit("setClients", []);

      commit("setClients", data.clients);
    }
    catch (error) {
      console.log("ERROR FETCH CLIENTS", error);
    }
  },
  async fetchClient({ commit }, id) {
    try {
      const { data, status } = await axiosIns.get(`clients/${id}`)

      if (status > 201) return commit('setClient', {})

      commit('setClient', data.client)
    } catch (error) {
      console.log('ERROR FETCHING CLIENT', error);
    }
  },
  async fetchClientsAdminApi() {
    try {
      const route = routes.admin_api.clients.listAll;

      const { data, status } = await axiosInsWheel.get(route);

      if (status > 201) return [];
      if (data.error) return [];

      return data.clients;
    } catch (error) {
      console.log('ERROR FETCH CLIENTS ADMIN API', error);
      return []
    }
  },
  async deleteClient({ commit }, { client }) {
    try {
      const { _id, uuid } = client;
      const rouletteApi = axiosIns.delete(`clients/${_id}`);
      const adminApi = axiosInsWheel.delete(`client/remove/${uuid}`)
      const data = await Promise.allSettled([rouletteApi, adminApi]);

      const { success, errors } = requestSuccess(...data);

      if (!success) {
        commit('setMessage', 'Error eliminando el cliente');
        return commit('setSuccess', false);
      }
      commit('setSuccess', true);
      store.commit("notification/setErrors", errors);
    } catch (error) {
      console.log('ERROR REMOVING CLIENT', error);
    }
  },
  async blockClient({ commit }, { id }) {
    try {
      const { data } = await axiosIns.put(`clients/${id}/block`);

      if (!data.ok) {
        commit("setMessage", data.msg)
        return commit("setSuccess", false);
      }

      commit("setMessage", data.msg);
      commit("setSuccess", true);

    } catch (error) {
      console.log('ERROR BLOCK CLIENT', error);
    }
  },
  async disblockClient({ commit }, { id }) {
    try {
      const { data } = await axiosIns.put(`clients/${id}/disblock`);

      if (!data.ok) {
        commit("setMessage", data.msg)
        return commit("setSuccess", false);
      }

      commit("setMessage", data.msg);
      commit("setSuccess", true);

    } catch (error) {
      console.log('ERROR BLOCK CLIENT', error);
    }
  },
  async fetchClientsRoulettes({ commit }) {
    try {
      const { data } = await axiosIns.get("/clients/roulettes")

      commit("setClientRoulettes", data.clientRoulettes)
    } catch (error) {
      console.log('ERROR FETCH CLIENT ROULETTES',);
    }
  },
  async updateClient({ commit }, { doc }) {

    const { _id: id, uuid } = doc;

    try {

      let data = null;

      if (!uuid) {
        let dataAdmin = null;
        let statusAdmin = null;

        try {
          const { data, status } = await store.dispatch('clients/createClientAdminApi', { dataToAdminApi: doc });
          dataAdmin = data
          statusAdmin = status
        } catch (error) {
          console.log('ERROR CREATING CLIENT WHEEL', error);
          store.commit("notification/setErrors", [{ reason: error, api: process.env.VUE_APP_WHEEL }]);
        }

        if (statusAdmin > 201) {
          const rouletteApi = axiosIns.put(`clients/${id}`, { ...doc, uuid: 1 });
          data = await Promise.allSettled([rouletteApi])
        } else {
          const uuid = dataAdmin ? dataAdmin.client.uuid : 1
          const rouletteApi = axiosIns.put(`clients/${id}`, { ...doc, uuid });
          data = await Promise.allSettled([rouletteApi])
        }
      } else {
        const adminApi = store.dispatch('clients/updateClientAdminApi', { uuid, doc });
        const rouletteApi = axiosIns.put(`clients/${id}`, { ...doc, uuid });
        data = await Promise.allSettled([rouletteApi, adminApi])
      }

      const { success, errors } = requestSuccess(...data);

      if (!success) {
        commit("setMessage", 'Error creando el cliente');
        return commit("setSuccess", false);
      }

      commit("setMessage", 'Cliente actualizado');
      commit("setSuccess", true);
      store.commit("notification/setErrors", errors);
    } catch (error) {
      console.log('ERROR UPDATE CLIENT', error);
    }
  },
  async updateClientAdminApi(_, { uuid, doc }) {

    try {
      return axiosInsWheel.put(`client/${uuid}`, doc)
    } catch (error) {
      console.log('ERROR UPDATE CLIENT', error);
      return null
    }
  },
  async createClient({ commit }, { doc }) {
    let uuid = '1'
    try {
      const { role, ...dataToSave } = doc;
      const { name, _id } = role;
      const dataToRoulletteApi = { ...dataToSave, role: _id };
      const dataToAdminApi = { ...dataToSave, lastName: name, rol: name };

      try {
        const { data: adminApi } = await store.dispatch('clients/createClientAdminApi', { dataToAdminApi });

        if (adminApi.client) uuid = adminApi.client.uuid
      } catch (error) {
        console.log('ERROR CREATING CLIENT WHEEL', error);
        store.commit("notification/setErrors", [{ reason: error, api: process.env.VUE_APP_WHEEL }]);
      }

      const { status } = axiosIns.post("clients", { ...dataToRoulletteApi, uuid });

      if (status > 201) {
        commit("setMessage", 'Error creando el cliente');
        return commit("setSuccess", false);
      }

      commit("setMessage", 'Cliente creado');
      commit("setSuccess", true);
    } catch (error) {
      console.log('ERROR CREATING CLIENT', error);
      commit("setSuccess", false)
    }
  },
  async createClientAdminApi(_, { dataToAdminApi }) {
    try {
      return axiosInsWheel.post("client", dataToAdminApi)
    } catch (error) {
      return null
    }
  },
  async importRoulette({ commit }, { id, from }) {

    try {

      const { data } = await axiosIns.put(`clients/${id}/import-roulettes`, { from });

      if (!data.ok) {
        commit("setMessage", data.msg)
        return commit("setSuccess", false)
      }

      commit("setMessage", data.msg)
      commit("setSuccess", true)
    } catch (error) {
      console.log('ERROR IMPORT ROULETTE', error);
    }
  },
  async addExternalGame({ commit }, { path, id, doc }) {
    try {
      const { data } = await axiosIns.post(`/clients/${id}/${path}`, doc);

      if (!data.ok) return commit("setSuccess", false);

      commit("setSuccess", true);
    } catch (error) {
      console.log('ERROR ADD EXTERNAL GAME', error);
      commit("setSuccess", true);

    }
  },
  async fetchExternalGames({ commit }, { id }) {
    try {
      const { data } = await axiosIns.get(`/clients/${id}/external-games`);

      commit("setGames", data.clientGames)
    } catch (error) {
      console.log('ERROR FECTH EXTERNAL GAMES', error);
    }
  },
  async removeRoulette({ commit }, { id }) {
    try {
      const { data } = await axiosIns.delete(`/clients/${id}/delete-roulette`);

      if (!data.ok) return commit("setSuccess", false);

      commit("setSuccess", true)
    } catch (error) {
      console.log('ERROR REMOVING ROULETTE', error);
      commit("setSuccess", false)
    }
  },
  // DRAGON TIGER
  async fetchDragonTiger({ commit }, { uuid }) {
    const clientRoute = routes.admin_api.clients;
    let route = `${clientRoute.path}/${uuid}/${clientRoute.games}`;
    try {
      const { data, status } = await axiosInsDragonTiger.get(route);

      if (status > 201) return commit('setDragonTigers', []);
      if (data.error) return commit('setDragonTigers', []);

      commit('setDragonTigers', data.games)
    } catch (error) {
      console.log('ERROR FETCH DRAGON TIGER', error);
    }
  },
  async addDragonTiger({ commit }, { route, gameUuid }) {
    try {
      const { data, status } = await axiosInsDragonTiger.put(route, { gameUuid });

      if (status > 201) return commit('setSuccess', false);
      if (data.error) return commit('setSuccess', false);

      commit('setSuccess', true)

    } catch (error) {
      console.log('ERROR ADD DRAGON TIGER', error);
      commit('setSuccess', false)
    }
  },
  // WHEEL
  async fetchWheels({ commit }, { uuid }) {

    try {
      const { data, status } = await axiosInsWheel.get(`client/${uuid}/all-games`);

      if (status > 201) return commit('setWheels', []);

      commit('setWheels', data.games)
    } catch (error) {
      console.log('ERROR FETCH WHEEL', error);
      commit('setWheels', []);
    }
  },
  async addWheel({ commit }, { uuid, gameUuid }) {
    try {
      const { status } = await axiosInsWheel.put(`client/${uuid}/add-game`, { gameUuid });

      if (status > 201) return commit('setSuccess', false);

      commit('setSuccess', true)
    } catch (error) {
      console.log('ERROR ADD DRAGON TIGER', error);
      commit('setSuccess', false)
    }
  },
  async removeWheel({ commit }, { uuid, gameUuid }) {
    try {
      const { status } = await axiosInsWheel.delete(`client/${uuid}/game/${gameUuid}`)

      if (status > 201) throw new Error('error')

      commit('setSuccess', true)
    } catch (error) {
      console.log('ERROR REMOVIENDO EL JUEGO DEL CLIENTE', error);
      commit('setSuccess', false)
    }
  },
  // GET GAMES
  async fetchGames(_, filter) {
    const { gameType, client } = filter

    try {
      let games = []

      switch (gameType) {
        case GamesTypes.Roulette:
          {
            await store.dispatch('clients/fetchClientRoulettes', { id: client });
            const roulettes = store.getters['clients/getClientRoulettes']
            if (!roulettes.length) return games = [];

            const formatData = roulettes.map((element) => {
              return element.roulette;
            });

            games = formatData
          }
          break;

        case GamesTypes.WheelFortune:
          {
            let filterUuid = await store.dispatch('reports/getFilterUuid', { filter, type: GamesTypes.WheelFortune })

            await store.dispatch('clients/fetchWheels', { uuid: filterUuid.client })

            games = store.getters['clients/getWheels']

            store.commit('wheel/setWheels', games)
          }
          break;
        default:
          break;
      }

      return games
    } catch (error) {
      console.log('ERROR FETFCHING GAMES OF CLIENT', error);
      return []
    }
  }
};