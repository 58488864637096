import store from "@/state/store";

export const formatRequest = (filter, filterData) => {
  let formatted = [];
  const { fromDate, toDate, roundId, roulette, operatorId: operator, clientId: client, isAdmin } =
    filterData;

  switch (filter.name) {
    case "APUESTAS POR RONDA":
      formatted = [
        {
          path: "/reports/bets-by-round",
          resp: "countBets",
          params: {
            round: roundId,
            roulette,
            // hour,
            isAdmin,
          },
        },
      ];
      break;
    case "APUESTAS POR DIA":
      formatted = [
        {
          path: "/reports/player-bet-by-day",
          resp: "count",
          params: {
            fromDate,
            toDate,
            roulette,
            // hour,
            isAdmin,
            operator,
            client
          },
        },
        {
          path: "/reports/bets-by-roulette",
          resp: "countBets",
          params: {
            fromDate,
            toDate,
            roulette,
            // hour,
            isAdmin,
            operator,
            client
          },
        },
        {
          path: `/visits`,
          resp: "resp",
          params: {
            fromDate,
            toDate,
            roulette,
            // hour,
            isAdmin,
            operator,
            client
          },
        },
      ];
      break;

    default:
      break;
  }

  return formatted;
};

export const formatResponse = (responses, filter) => {
  const formattedData = {};
  const formattedFields = [];
  const { isAdmin } = filter;
  let rows = [];
  const row = {};

  for (let res of responses) {
    const { status, data, request } = res;
    const { responseURL } = request;
    if (status > 201) continue;

    if (responseURL.includes("visits")) {
      Object.assign(formattedData, {
        visits: isAdmin ? data.totalVisits : data.visitsPlayers,
      });
      if (data.data.length) {
        const { rouletteName: roulette } = data.data[0];
        const el = {
          roulette,
          visits: isAdmin ? data.totalVisits : data.visitsPlayers,
        };
        Object.assign(row, el);
      }

      store.commit("reports/setVisits", data);

      formattedFields.push(
        {
          key: "visits",
          label: "JUGADORES QUE VISITARON EN LA FECHA ASIGNADA",
        },
        {
          key: "roulette",
          label: "JUEGO",
        }
      );
    } else if (responseURL.includes("player")) {
      Object.assign(formattedData, { bets: data.count });
      const el = { bets: data.count };
      Object.assign(row, el);

      formattedFields.push({
        key: "bets",
        label: "JUGADORES QUE APOSTARON",
      });
    } else if (responseURL.includes("bets-by-roulette")) {
      Object.assign(formattedData, { betsByRoulette: data.countBets });
      const el = { betsByRoulette: data.countBets };
      Object.assign(row, el);

      formattedFields.push({
        key: "betsByRoulette",
        label: "APUESTAS EN EL JUEGO",
      });
    } else {
      Object.assign(formattedData, {
        bets: data.countBets,
        views: data.usersOnline,
        roulette: data.roulette,
      });

      for (let key in formattedData) {
        const data = formattedData[key];
        Object.assign(row, {
          [key]: data,
        });
      }

      formattedFields.push(
        {
          key: "bets",
          label: "CANT. DE APUESTAS POR RONDA",
        },
        {
          key: "views",
          label: "JUGADORES EN LINEA",
        },
        {
          key: "roulette",
          label: "JUEGO",
        }
      );
    }
  }

  rows.push(row);

  return {
    formattedData: rows,
    formattedFields,
  };
};
