import moment from "moment";
import i18n from "../../i18n";
import { GamesTypes, responseTransactionGameType } from ".";

export const formatTransaction = (mapTransactions, gameType) => {
  const formattedData = [];

  if (gameType !== GamesTypes.Roulette) return mapTransactions

  mapTransactions.forEach((element) => {
    formattedData.push({
      ...element,
      identifierNumber: element.round
        ? element.round.identifierNumber
        : "No round",
      roundId: element.round ? element.round.code : "No round",
      client: element.client ? element.client.name : "No client",
      operator: element.operator
        ? element.operator.name
        : "No operator",
      playerId: element.player ? element.player.userId : "No ID player",
      player: element.player ? element.player.username : "No player",
      WL: element.player.WL ? element.player.WL : "-",
      roulette: element.game ? element.game.name : "No roulette",
      currency: element.currency
        ? element.currency.short
        : "No currency",
      transactionId: element.bet
        ? element.bet.transactionId
        : "No transaction",
      betId: element.bet
        ? element.bet._id
        : "No transaction",
      pleno: element.game ? element.game.pleno : 0,
    });
  });

  return formattedData
}

export const formatExportTransaction = (mapTransactions) => {
  const formattedData = []
  mapTransactions.forEach((element) => {
    formattedData.push({
      ...element,
      identifierNumber: element.round
        ? element.round.identifierNumber
        : "No round",
      roundId: element.round ? element.round.code : "No round",
      client: element.client ? element.client.name : "No client",
      operator: element.operator ? element.operator.name : "No operator",
      playerId: element.player ? element.player.userId : "No ID player",
      player: element.player ? element.player.username : "No player",
      WL: element.player.WL ? element.player.WL : "-",
      roulette: element.game ? element.game.name : "No roulette",
      currency: element.currency ? element.currency.short : "No currency",
      transactionId: element.bet
        ? element.bet.transactionId
        : "No transaction",
      pleno: element.game ? element.game.pleno : 0,
      createdAt: moment(element.createdAt).format(
        "DD/MM/YYYY | HH:mm:ss"
      ),
      debit: element.type === "debit" ? element.amount.toFixed(2) : 0,
      credit: element.type === "credit" ? element.amount.toFixed(2) : 0,
      errorRound: element.providerresult ? element.providerresult : 0,
      rollbackRound: element.providerresult ? element.providerresult : 0,
      retryRound: element.providerresult ? element.providerresult : 0,
      rateUSD: element.currencyExchangeDollar
        ? element.currencyExchangeDollar
        : "NO EXCHANGE",
      betUSD: element.amountExchangeDollar
        ? element.type === "debit"
          ? String(element.amountExchangeDollar.toFixed(4)).replace(
            ".",
            ","
          )
          : 0
        : "NO EXCHANGE",
      winUSD: element.amountExchangeDollar
        ? element.type === "credit"
          ? String(element.amountExchangeDollar.toFixed(4)).replace(
            ".",
            ","
          )
          : 0
        : "NO EXCHANGE",
    });
  });
  return formattedData
}

export const formatAllTransactions = (values, options) => {

  const { limit } = options.params

  const transactions = []

  for (let data of values) {
    const { value } = data
    if (!value) continue
    transactions.push(...value)
  }

  const sortedTransactions = transactions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return sortedTransactions.map(transaction => {
    return {
      ...transaction,
      ROUND: typeof transaction.round === 'string' ? transaction.round : transaction.round.identifierNumber,
      client: typeof transaction.client === 'string' ? transaction.client : transaction.client.name,
      operator: transaction.operator
        ? transaction.operator.name || transaction.operator
        : transaction.player.operator.name,
      USERNAME: transaction.player.username,
      player: transaction.player.username,
      USER_ID: transaction.player.userId,
      WL: transaction.player.WL,
      GAME: transaction.game.name,
      gameType: responseTransactionGameType[transaction.game.type] || responseTransactionGameType[transaction.game.gameType],
      CURRENCY: transaction.currency.short || transaction.currency.isoCode,
      BET_ID: transaction.bet ? transaction.uuid || transaction.bet._id
        : '-',
      TRANSACTION_ID: transaction.bet ? transaction.bet.uuid || transaction.bet.transactionId : '-',
      type: transaction.type,
      amount: transaction.amount,
      debit: transaction.type === 'debit' ? transaction.amount : 0,
      credit: transaction.type === 'credit' ? transaction.amount : 0,
      userBalance: transaction.userBalance,
      createdAt: transaction.createdAt,
      playerIp: transaction.playerIp,
      platform: transaction.platform,
      playerCountry: transaction.playerCountry,
      userAgent: transaction.userAgent,
    }
  }).slice(0, limit)
}

export const transactionAllFields = [
  {
    key: "ROUND",
    sortable: true,
    label: i18n.t("transactions.roundID").toUpperCase(),
    class: "space-nowrap",
  },
  {
    key: "client",
    sortable: true,
    label: i18n.t("tableReports.client").toUpperCase(),
  },
  {
    key: "operator",
    sortable: true,
    label: i18n.t("tableReports.operator").toUpperCase(),
  },
  {
    key: "USERNAME",
    sortable: true,
    label: i18n.t("betDetail.user").toUpperCase(),
  },
  {
    key: "USER_ID",
    sortable: true,
    label: i18n.t("tableReports.userId").toUpperCase(),
    class: "space-nowrap text-center",
  },
  {
    key: "WL",
    sortable: true,
    label: "WL",
    class: "space-nowrap",
  },
  {
    key: "GAME",
    sortable: true,
    label: i18n.t("tableReports.casinoName").toUpperCase(),
    class: "space-nowrap",
  },
  {
    key: "CURRENCY",
    sortable: true,
    label: i18n.t("tableReports.currency").toUpperCase(),
  },
  {
    key: "BET_ID",
    sortable: true,
    label: 'BET ID',
    class: "space-nowrap",
  },
  {
    key: "TRANSACTION_ID",
    sortable: true,
    label: i18n.t("tableReports.transactionId").toUpperCase(),
    class: "space-nowrap",
  },
  {
    key: "type",
    sortable: true,
    label: i18n.t("tableReports.transactionType").toUpperCase(),
    class: "space-nowrap text-center",
  },
  {
    key: "debit",
    sortable: true,
    label: i18n.t("tableReports.debit").toUpperCase(),
    class: "text-end",
  },
  {
    key: "credit",
    sortable: true,
    label: i18n.t("tableReports.credit").toUpperCase(),
    class: "text-end",
  },
  {
    key: "userBalance",
    sortable: true,
    label: i18n.t("tableReports.balance").toUpperCase(),
    class: "text-end",
  },
  {
    key: "details",
    sortable: true,
    label: i18n.t("tableReports.details").toUpperCase(),
  },
  {
    key: "createdAt",
    sortable: true,
    label: i18n.t("tableReports.date").toUpperCase(),
    class: "space-nowrap",
  },
  {
    key: "playerIp",
    sortable: true,
    label: "IP".toUpperCase(),
  },
  {
    key: "platform",
    sortable: true,
    label: i18n.t("tableReports.deviceType").toUpperCase(),
    class: "space-nowrap",
  },
  {
    key: "playerCountry",
    sortable: true,
    label: i18n.t("tableReports.country").toUpperCase(),
    class: "space-nowrap",
  },
  {
    key: "userAgent",
    sortable: true,
    label: "User Agent".toUpperCase(),
    class: "space-nowrap",
  },
];