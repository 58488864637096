import store from '@/state/store'
import { axiosIns, axiosInsWheel } from "../../helpers/axios/index";
import { BOILER_PLATE_GAMES, EXTERNAL_GAMES, ROULETTE } from '../../helpers/CONSTANTS';
import { GamesTypes } from '../helpers/gamesTypes';
import { rouletteModules } from '../helpers/rouletteJackpotsModules';

export const state = {
  currencies: [],
  games: [],
  success: false,
  jackpot: false,
  fetching: false,
  modules: [
    { id: 1 }
  ],
  profit: 0
};

export const getters = {
  getCurrencies() {
    return state.currencies;
  },
  getGames() {
    return state.games;
  },
  getJackpot() {
    return state.jackpot;
  },
  isSuccess() {
    return state.success
  },
  isFetching() {
    return state.fetching
  },
  getRoulettesJackpotsModule: () => (id) => {
    const jackpot_module = rouletteModules.find((module) => module.id === id)
    return jackpot_module
  },
  getProfit() {
    return state.profit
  },
  getModules() {
    return state.modules.map(item => ({ id: item }))
  },
};

export const mutations = {
  setCurrencies(state, payload) {
    state.currencies = [];
    state.currencies = payload;
  },
  setGames(state, payload) {
    state.currencies = [];
    state.games = payload;
  },
  setJackpot(state, payload) {
    state.jackpot = payload;
  },
  setSuccess(state, payload) {
    state.success = payload;
  },
  setFetching(state, payload) {
    state.fetching = payload
  },
  setModules(state, payload) {
    state.modules = payload
  },
  setProfit(state, payload) {
    state.profit = payload
  },
};

export const actions = {
  async fetchGameCurrencies({ commit }, { path, id }) {
    try {
      const { data } = await axiosIns.get(`${path}/${id}/currencies`);

      if (!data.ok) {
        commit("setCurrencies", []);
        return false;
      }

      const currencies = data.currencies.map((curr) => curr);

      commit("setCurrencies", currencies);
      return data;
    } catch (err) {
      console.log("Error fetchGameCurrencies", err);
      commit("setCurrencies", []);
    }
  },
  async addCurrencies({ commit }, { path, currencies, id }) {
    try {
      const { data } = await axiosIns.post(`${path}/${id}/add-currencies`, {
        currencies,
      });

      if (!data.ok) {
        return data;
      }

      commit("setCurrencies", data.currenciesSaved);
      return data;
    } catch (error) {
      console.log("ERROR addCurrencies", error);
    }
  },
  async updateCurrencies({ commit }, { path, id, currencies }) {
    try {
      const { data } = await axiosIns.put(`${path}/${id}/update-currencies`, {
        currencies,
      });

      if (!data.ok) {
        return data;
      }

      commit("setCurrencies", data.currencies);
      return data;
    } catch (error) {
      console.log("ERROR UPDATE CURRENCIES", error);
    }
  },
  // ! chips
  async addChips({ commit }, { path, id, form }) {
    try {
      const { data } = await axiosIns.post(`${path}/${id}/assign-chips`, {
        ...form,
      });

      if (!data.ok) return data;

      commit("setCurrencies", []);
      return data;
    } catch (error) {
      console.log("ERROR ADD CHIPS", error);
      return false;
    }
  },
  async findChips({ commit }, { path, id, filter }) {
    // /dragon-tiger/63ffb0d48c8fc53b0c9333e5/show-chips?currency=637cd3004b073726e4b7d573&
    try {
      const { data } = await axiosIns.get(`${path}/${id}/show-chips`, {
        params: { ...filter },
      });

      if (!data.ok) return data;

      commit("setCurrencies", []);
      return data;
    } catch (error) {
      console.log("ERROR IN FIND CHIPS", error);
      return false;
    }
  },
  // FIND GAMES
  async findGames({ commit }, { path, options, type }) {
    try {
      let data = {};
      let status = 0;
      let response = type === EXTERNAL_GAMES ? 'games' : type;

      switch (type) {
        case ROULETTE: {
          const { data: dataRoulette, status: statusRoulette } = await axiosIns.get(`/${path}`, options)

          data = dataRoulette;
          status = statusRoulette
        }
          break;
        case EXTERNAL_GAMES: {
          const { data: dataRoulette, status: statusRoulette } = await axiosIns.get(`/${path}`, options)

          data = dataRoulette;
          status = statusRoulette
        }
          break;

        case BOILER_PLATE_GAMES: {
          const { data: dataWheel } = await axiosInsWheel.get('game/all')
          data = dataWheel
        }
          break;
        default:
          break;
      }

      if (status > 200) return commit("setGames", []);
      if (!Object.entries(data).length) return commit("setGames", []);

      commit("setGames", data[response])
    } catch (error) {
      console.log('ERROR FIND GAMES', error);
      commit("setGames", [])
    }
  },
  async deleteExternalGame({ commit }, { id, model = 'operators' }) {
    try {
      const { data } = await axiosIns.delete(`/${model}/${id}/delete-external-game`);

      if (!data.ok) return commit('setSuccess', false);

      commit('setSuccess', true)
    } catch (err) {
      console.log('ERROR DELETE EXTERNAL GAME', err);
      commit('setSuccess', false)
    }
  },
  async fetchGames({ commit }, { gameType }) {
    let games = [];

    try {
      switch (gameType) {
        case GamesTypes.Roulette:
          {
            await store.dispatch('games/findGames', { path: "roulettes", options: {}, type: "roulettes" });
            games = store.getters['games/getGames'];
          }
          break;
        case GamesTypes.ExternalGames:
          {
            await store.dispatch('games/findGames', { path: "external-games", options: {}, type: "external-games" });
            games = store.getters['games/getGames'];
          }
          break;
        case GamesTypes.DragonTiger:
          {
            await store.dispatch('dragonTiger/fetchGames');
            games = store.getters['dragonTiger/getGames'];
          }
          break;
        case GamesTypes.WheelFortune:
          {
            await store.dispatch('wheel/fetchWheels');
            games = store.getters['wheel/getWheels'];
          }
          break;
        case GamesTypes.BlackJack:
          {
            await store.dispatch('blackJack/fetchGames');
            games = store.getters['blackJack/getGames'];
          }
          break;
        case GamesTypes.ALL:
          {
            games = await store.dispatch('games/fetchAll');
          }
          break;
        default:
          {
            games = await store.dispatch('games/fetchAll')
          }
          break;
      }

      commit('setGames', games);
    } catch (error) {
      console.log('ERROR FETCH GAMES', error);
    }
  },
  async fetchAll() {
    const games = []
    try {
      await store.dispatch('games/findGames', { path: "roulettes", options: {}, type: "roulettes" });
      games.push(...store.getters['games/getGames'])
      await store.dispatch('games/findGames', { path: "external-games", options: {}, type: "external-games" });
      games.push(...store.getters['games/getGames'])
      await store.dispatch('wheel/fetchWheels');
      games.push(...store.getters['wheel/getWheels'])

      return games
    } catch (error) {
      console.log('ERROR FETCH ALL GAMES', error);
    }
  },
  async selectApiUrl(_, { type }) {
    let url = ''

    switch (type) {
      case GamesTypes.Roulette:
        url = process.env.VUE_APP_API
        break;
      case GamesTypes.WheelFortune:
        url = process.env.VUE_APP_WHEEL
        break;
      default:
        url = ''
    }

    return url
  },
  async updateRoulette({ commit }, game) {
    const { _id } = game
    try {
      const { status } = await axiosIns.put(`roulettes/${_id}`, game)

      if (status > 201) return commit('setSuccess', false)

      commit('setSuccess', true)
    } catch (error) {
      console.log('ERROR UPDATING ROULETTE', error);
      commit('setSuccess', false)
    }
  },
  async updateOpenClingTime({ commit }, { data, type }) {
    try {

      switch (type) {
        case GamesTypes.Roulette:
          {
            const { status } = await axiosIns.put(`roulettes/${data._id}`, data)

            if (status > 201) throw new Error('ERROR UPDATING ROULETTE')
          }
          break;
        case GamesTypes.WheelFortune:
          {
            const { status } = await axiosInsWheel.patch(`wheel/${data.uuid}/opening-closing-time`, data)

            if (status > 201) throw new Error('ERROR UPDATING WHEEL')
          }
          break;

        default:
          throw new Error('Falta el game type')
      }

      commit('setSuccess', true)
    } catch (error) {
      console.log('ERROR UPDATE OPENING CLOSING TIME', error);
      commit('setSuccess', false)
    }
  },
  async fetchRoulettesJackpotsModule({ commit }, rouletteId) {
    try {
      const { data } = await axiosIns.get(`/roulettes/roulette/module`, {
        params: {
          rouletteId
        }
      })

      if (!data.ok) throw new Error(data.msg)

      commit('setModules', data.rouletteModule.modules)
      commit('setProfit', data.rouletteModule.profit)
    } catch (error) {
      console.log('ERROR GETTING ROULETTES JACKPOTS MODULE', error);
      commit('setModules', [])
      commit('setProfit', 0)
    }
  }
};
