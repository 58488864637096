import i18n from "../../i18n";

const rouletteRoundsFields = {
  globalFields: [
    {
      key: "identifierNumber",
      sortable: true,
      label: "ID".toUpperCase(),
    },
    {
      key: "code",
      sortable: true,
      label: "Round ID".toUpperCase(),
      class: "space-nowrap",
    },
    {
      key: "name",
      sortable: true,
      label: i18n.t("betDetail.roulette").toUpperCase(),
    },
    {
      key: "start_date",
      sortable: true,
      label: i18n.t("tableReports.initDate").toUpperCase(),
      class: "space-nowrap",
    },
    {
      key: "end_date",
      sortable: true,
      label: i18n.t("tableReports.endDate").toUpperCase(),
      class: "space-nowrap",
    },
    {
      key: "status",
      sortable: true,
      label: i18n.t("tableReports.status").toUpperCase(),
    },
    {
      key: "result",
      sortable: true,
      label: i18n.t("tableReports.result").toUpperCase(),
      class: "text-center",
    },
    {
      key: "jackpotRound",
      sortable: true,
      label: i18n.t("betDetail.jackpots").toUpperCase(),
      class: "space-nowrap",
    },
  ],
  adminFields: [
    {
      key: "roundBets",
      label: "Round Bets".toUpperCase(),
      class: "text-center",
    },
    {
      key: "video",
      sortable: true,
      label: "Video".toUpperCase(),
    },
    {
      key: "sisterRound",
      sortable: true,
      label: i18n.t("tableReports.roundSister").toUpperCase(),
      class: "space-nowrap",
    },
  ],
};

const dragonTigerRoundsFields = {
  globalFields: [
    {
      key: "identifierNumber",
      sortable: true,
      label: "ID".toUpperCase(),
    },
    {
      key: "name",
      sortable: true,
      label: "juego".toUpperCase(),
    },
    {
      key: "start_date",
      sortable: true,
      label: i18n.t("tableReports.initDate").toUpperCase(),
      class: "space-nowrap",
    },
    {
      key: "end_date",
      sortable: true,
      label: i18n.t("tableReports.endDate").toUpperCase(),
      class: "space-nowrap",
    },
    {
      key: "open",
      sortable: true,
      label: i18n.t("tableReports.status").toUpperCase(),
    },
    {
      key: "winner",
      sortable: true,
      label: i18n.t("tableReports.result").toUpperCase(),
      class: "text-center",
    },
    {
      key: "result",
      sortable: true,
      label: "CARTAS".toUpperCase(),
      class: "text-center",
    },
  ],
  adminFields: [],
};

const wheelRoundsFields = {
  globalFields: [
    {
      key: "identifierNumber",
      sortable: true,
      label: "ID".toUpperCase(),
    },
    {
      key: "name",
      sortable: true,
      label: "juego".toUpperCase(),
    },
    {
      key: "start_date",
      sortable: true,
      label: i18n.t("tableReports.initDate").toUpperCase(),
      class: "space-nowrap",
    },
    {
      key: "end_date",
      sortable: true,
      label: i18n.t("tableReports.endDate").toUpperCase(),
      class: "space-nowrap",
    },
    {
      key: "jackpotRound",
      sortable: true,
      label: i18n.t("betDetail.jackpots").toUpperCase(),
      class: "space-nowrap",
    },
    {
      key: "open",
      sortable: true,
      label: i18n.t("tableReports.status").toUpperCase(),
    },
    {
      key: "result",
      sortable: true,
      label: i18n.t("tableReports.result").toUpperCase(),
      class: "text-center",
    },
  ],
  adminFields: [],
};

const blackJackRoundFields = {
  globalFields: [
    {
      key: "identifyNumber",
      sortable: true,
      label: "ID".toUpperCase(),
    },
    {
      key: "game.name",
      sortable: true,
      label: "juego".toUpperCase(),
    },
    {
      key: "start_date",
      sortable: true,
      label: i18n.t("tableReports.initDate").toUpperCase(),
      class: "space-nowrap",
    },
    {
      key: "end_date",
      sortable: true,
      label: i18n.t("tableReports.endDate").toUpperCase(),
      class: "space-nowrap",
    },
    {
      key: "jackpotRound",
      sortable: true,
      label: i18n.t("betDetail.jackpots").toUpperCase(),
      class: "space-nowrap",
    },
    {
      key: "open",
      sortable: true,
      label: i18n.t("tableReports.status").toUpperCase(),
    },
    {
      key: "result",
      sortable: true,
      label: i18n.t("tableReports.result").toUpperCase(),
      class: "text-center",
    },
  ],
  adminFields: [],
};

const allGamesFields = {
  globalFields: [
    {
      key: "identifierNumber",
      sortable: true,
      label: "ID".toUpperCase(),
    },
    {
      key: "gameName",
      sortable: true,
      label: "GAME".toUpperCase(),
    },
    {
      key: "start_date",
      sortable: true,
      label: i18n.t("tableReports.initDate").toUpperCase(),
      class: "space-nowrap",
    },
    {
      key: "end_date",
      sortable: true,
      label: i18n.t("tableReports.endDate").toUpperCase(),
      class: "space-nowrap",
    },
    {
      key: "status",
      sortable: true,
      label: i18n.t("tableReports.status").toUpperCase(),
    },
    {
      key: "globalResult",
      sortable: true,
      label: i18n.t("tableReports.result").toUpperCase(),
      class: "text-center",
    },
    {
      key: "JACKPOT",
      sortable: true,
      label: i18n.t("betDetail.jackpots").toUpperCase(),
      class: "space-nowrap",
    },
  ],
};

export {
  rouletteRoundsFields,
  dragonTigerRoundsFields,
  wheelRoundsFields,
  blackJackRoundFields,
  allGamesFields,
};
