import { axiosIns, axiosInsWheel } from "../../helpers/axios/index";

export const state = {
  logs: []
}

export const getters = {
  getLogs() {
    return state.logs
  }
}

export const mutations = {
  setLogs(state, payload) {
    state.logs = payload;
  }
}

export const actions = {
  async fetchLogs({ commit }, { params, type }) {
    try {
      const data = await fetchLogsByType({ params, type });

      commit('setLogs', data)
    } catch (error) {
      console.log('ERROR FETCH LOGS', error);
      commit('setLogs', [])
    }
  }
}

const fetchLogsByType = async ({ params, type }) => {
  let data;

  try {
    switch (type) {
      case LOGS.roulette:
        {

          const { data: dataRoulette } = await axiosIns.get('logs/all', {
            params
          })

          if (!dataRoulette.ok) {
            data = []
          } else {
            data = dataRoulette.data;
          }

        }
        break;
      case LOGS.boilerplate:
        {

          const { data: dataBoilerPlate, status } = await axiosInsWheel.get('log', {
            params
          })

          if (status > 201) {
            data = []
          } else {
            const { logs } = dataBoilerPlate;

            data = logs;
          }


        }
        break;

      default:
        data = [];
        break;
    }
  } catch (error) {
    console.log('ERROR FETCH LOGS BY TYPE', error);
    data = [];
  }

  return data
}

export const LOGS = {
  roulette: 'LOGS RULETA',
  boilerplate: 'LOGS OTROS JUEGOS'
}

export const errorTypes = {
  credit: 'credit',
  debit: 'debit',
  rollback: 'rollback',
  round: 'round'
}