export const formatData = (...dataModel) => {
  const data = [];
  const modelNames = []
  for (let collections of dataModel) {

    if (!collections.length) continue;

    for (let collection of collections) {

      if (modelNames.includes(collection.name)) {
        const { uuid, client: clientUuid, gamesPayments, usdRateChange, limits, currencies, operatorGamesLimits } = collection;
        const index = data.findIndex((c) => c.name === collection.name);
        const collectionToUpdate = data[index];
        const newCollection = { ...collectionToUpdate, uuid, clientUuid, gamesPayments, usdRateChange, limits, currencies, operatorGamesLimits };
        data[index] = newCollection;
        continue
      }

      modelNames.push(collection.name);
      data.push(collection);
    }
  }

  return data
}

export const mergeChips = (rouletteApi, adminApi) => {
  const data = [];

  for (let chip of rouletteApi) {
    let sameChip;

    if (adminApi) {
      sameChip = adminApi.find(ch => (
        ch.currency === chip.operatorCurrency[0].short
        && ch.color.primary === chip.color.primary
        && ch.color.secondary === chip.color.secondary
        && ch.value === chip.value
      ));

    }

    if (!sameChip) {
      data.push(chip);
      continue
    }
    const { uuid, currency } = sameChip;
    const newChip = { ...chip, uuid, currencyShort: currency };
    data.push(newChip);
  }

  return data
}