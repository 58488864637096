import axiosInsDragonTiger from "../../helpers/axios/dragonTiger";

export const state = {
  games: [],
  success: false,
  winner: false,
  earnings: 0
}
export const getters = {
  getGames() {
    return state.games
  },
  isSuccess() {
    return state.success
  },
  isWinner() {
    return state.winner
  },
  getEarnings() {
    return state.earnings
  }
}
export const mutations = {
  setGames(state, payload) {
    state.games = payload;
  },
  setSuccess(state, payload) {
    state.success = payload;
  },
  setWinner(state, payload) {
    state.winner = payload;
  },
  setEarnings(state, payload) {
    state.earnings = payload;
  },
}
export const actions = {
  async fetchGames({ commit }) {
    try {
      const routes = getRoutes();

      const { data } = await axiosInsDragonTiger.get(routes.getAll.path);

      commit("setGames", data[routes.getAll.response]);

    } catch (error) {
      console.log('ERROR IN FETCH GAMES', error);
    }
  },
  async createBet({ commit }, { bet }) {
    try {
      const routes = getRoutes();
      const { data, status } = await axiosInsDragonTiger.post(routes.createBet.path, bet);

      if (status > 201) return commit("setSuccess", false)
      if (data.error) return commit("setSuccess", false)
      commit("setSuccess", true);
    } catch (error) {
      console.log('ERROR CREATE BET', error);
      commit("setSuccess", false);
    }
  },
  async getWinner({ commit }, { options }) {
    try {
      const routes = getRoutes();
      const { data } = await axiosInsDragonTiger.get(routes.getWinner.path, options)

      const {
        win,
        totalEarnings, } = data;

      if (!win) return commit("setWinner", false);

      commit("setWinner", true);
      commit("setEarnings", totalEarnings);
    } catch (error) {
      console.log('ERROR GET WINNER', error);
    }
  },
  async createGame({ commit }, form) {
    try {
      const { data } = await axiosInsDragonTiger.post("/dragon-tiger/create", form);

      if (data.error) return commit("setSuccess", false)

      commit("setSuccess", true)
    } catch (error) {
      console.log('ERROR CREATE GAME', error);
      commit("setSuccess", false)
    }
  },
  async updateGame({ commit }, { id, dataToUpdate }) {
    try {

      const { data } = await axiosInsDragonTiger.put(`/dragon-tiger/update/${id}`, dataToUpdate)

      if (data.error) return commit("setSuccess", false)

      commit("setSuccess", true)
    } catch (error) {
      console.log('ERROR UPDATE GAME', error);
      commit("setSuccess", false)
    }
  },
  async deleteGame({ commit }, uuid) {
    try {
      const { data } = await axiosInsDragonTiger.delete(`/dragon-tiger/remove/${uuid}`);

      if (data.error) return commit("setSuccess", false)

      commit("setSuccess", true)
    } catch (error) {
      console.log('ERROR DELETE GAME', error);
      commit("setSuccess", false)
    }
  },
  async deleteGameOfModel({ commit }, { model, gameUuid }) {
    try {
      const routes = getRoutes();
      const path = Object.keys(model).includes(routes.client.path) ? routes.operator.path : routes.client.path;
      const { uuid } = model;
      const { data, status } = await axiosInsDragonTiger.delete(`/${path}/${uuid}/game/${gameUuid}`);

      if (data.error) return commit('setSuccess', false);
      if (status > 201) return commit('setSuccess', false);

      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR DELETE GAME OF MODEL --> DRAGON TIGER', error);
      commit('setSuccess', false);
    }
  }
}

const getRoutes = () => {
  const routes = process.env.VUE_APP_DRAGON_TIGER_API ? dragonTigerApiRoutes.boilerplate_api : dragonTigerApiRoutes.roulette_api
  return routes
}

export const dragonTigerApiRoutes = {
  roulette_api: {
    getAll: { path: "/dragon-tiger", response: "dragonTigers" },
    createBet: { path: "dragon-tiger/bets" },
    getWinner: { path: "dragon-tiger/bets/winner" },
  },
  boilerplate_api: {
    getAll: { path: "/dragon-tiger/all", response: "dragonTigers" },
    createBet: { path: "/bet/create" },
    getWinner: { path: "/bet/winner" },
    client: { path: 'client' },
    operator: { path: 'operator' },
  }
}