import i18n from "../../i18n";

export const historyRouletteFields = [
  {
    key: "round.identifierNumber",
    sortable: true,
    label: i18n.t('transactions.roundID').toUpperCase(),
    class: "space-nowrap",
  },
  {
    key: "round.code",
    sortable: true,
    label: i18n.t('transactions.roundCode').toUpperCase(),
    class: "space-nowrap",
  },
  {
    key: "game.name",
    sortable: true,
    label: i18n.t('betDetail.roulette').toUpperCase(),
    class: "space-nowrap",
  },
  {
    key: "client",
    sortable: true,
    label: i18n.t('tableReports.client').toUpperCase(),
    class: "space-nowrap",
  },
  {
    key: "operator",
    sortable: true,
    label: i18n.t('tableReports.operator').toUpperCase(),
  },
  {
    key: "playerId",
    sortable: true,
    label: i18n.t('tableReports.userId').toUpperCase(),
    class: "space-nowrap text-center",
  },
  {
    key: "player",
    sortable: true,
    label: i18n.t('betDetail.user').toUpperCase(),
  },
  {
    key: "platform",
    sortable: true,
    label: "Platform".toUpperCase(),
  },
  {
    key: "currency",
    sortable: true,
    label: i18n.t('tableReports.currency').toUpperCase(),
  },
  {
    key: "totalBet",
    sortable: true,
    label: i18n.t('betDetail.totalBet').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "totalWin",
    sortable: true,
    label: i18n.t('betDetail.totalWin').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "rateUSD",
    sortable: true,
    label: i18n.t('tableReports.rateUSD').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "betUSD",
    sortable: true,
    label: i18n.t('tableReports.betUSD').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "winUSD",
    sortable: true,
    label: i18n.t('tableReports.winUSD').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "details",
    sortable: true,
    label: i18n.t('tableReports.details').toUpperCase(),
  },
  {
    key: "createdAt",
    sortable: true,
    label: i18n.t("tableReports.date").toUpperCase(),
    class: "space-nowrap",
  },
]

export const historyAllFields = [
  {
    key: "round.identifierNumber",
    sortable: true,
    label: i18n.t('transactions.roundID').toUpperCase(),
    class: "space-nowrap",
  },
  {
    key: "game.name",
    sortable: true,
    label: i18n.t('betDetail.roulette').toUpperCase(),
    class: "space-nowrap",
  },
  {
    key: "client",
    sortable: true,
    label: i18n.t('tableReports.client').toUpperCase(),
    class: "space-nowrap",
  },
  {
    key: "operator",
    sortable: true,
    label: i18n.t('tableReports.operator').toUpperCase(),
  },
  {
    key: "USER_ID",
    sortable: true,
    label: i18n.t('tableReports.userId').toUpperCase(),
    class: "space-nowrap text-center",
  },
  {
    key: "player",
    sortable: true,
    label: i18n.t('betDetail.user').toUpperCase(),
  },
  {
    key: "platform",
    sortable: true,
    label: "Platform".toUpperCase(),
  },
  {
    key: "CURRENCY",
    sortable: true,
    label: i18n.t('tableReports.currency').toUpperCase(),
  },
  {
    key: "totalBet",
    sortable: true,
    label: i18n.t('betDetail.totalBet').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "totalWin",
    sortable: true,
    label: i18n.t('betDetail.totalWin').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "rateUSD",
    sortable: true,
    label: i18n.t('tableReports.rateUSD').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "betUSD",
    sortable: true,
    label: i18n.t('tableReports.betUSD').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "winUSD",
    sortable: true,
    label: i18n.t('tableReports.winUSD').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "details",
    sortable: true,
    label: i18n.t('tableReports.details').toUpperCase(),
  },
  {
    key: "createdAt",
    sortable: true,
    label: i18n.t("tableReports.date").toUpperCase(),
    class: "space-nowrap",
  },
]

export const historyWheelFields = [
  {
    key: "round.identifierNumber",
    sortable: true,
    label: i18n.t('transactions.roundID').toUpperCase(),
    class: "space-nowrap",
  },
  {
    key: "game.name",
    sortable: true,
    label: i18n.t('betDetail.roulette').toUpperCase(),
    class: "space-nowrap",
  },
  {
    key: "client",
    sortable: true,
    label: i18n.t('tableReports.client').toUpperCase(),
    class: "space-nowrap",
  },
  {
    key: "operator",
    sortable: true,
    label: i18n.t('tableReports.operator').toUpperCase(),
  },
  {
    key: "player.userId",
    sortable: true,
    label: i18n.t('tableReports.userId').toUpperCase(),
    class: "space-nowrap text-center",
  },
  {
    key: "player.username",
    sortable: true,
    label: i18n.t('betDetail.user').toUpperCase(),
  },
  {
    key: "platform",
    sortable: true,
    label: "Platform".toUpperCase(),
  },
  {
    key: "currency.isoCode",
    sortable: true,
    label: i18n.t('tableReports.currency').toUpperCase(),
  },
  {
    key: "totalBet",
    sortable: true,
    label: i18n.t('betDetail.totalBet').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "totalWin",
    sortable: true,
    label: i18n.t('betDetail.totalWin').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "rateUSD",
    sortable: true,
    label: i18n.t('tableReports.rateUSD').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "betUSD",
    sortable: true,
    label: i18n.t('tableReports.betUSD').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "winUSD",
    sortable: true,
    label: i18n.t('tableReports.winUSD').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "details",
    sortable: true,
    label: i18n.t('tableReports.details').toUpperCase(),
  },
  {
    key: "createdAt",
    sortable: true,
    label: i18n.t("tableReports.date").toUpperCase(),
    class: "space-nowrap",
  },
]

export const historyExternalGamesFields = [
  // {
  //   key: "round",
  //   sortable: true,
  //   label: i18n.t('transactions.roundID').toUpperCase(),
  //   class: "space-nowrap",
  // },
  {
    key: "game.name",
    sortable: true,
    label: i18n.t('betDetail.roulette').toUpperCase(),
    class: "space-nowrap",
  },
  {
    key: "client.name",
    sortable: true,
    label: i18n.t('tableReports.client').toUpperCase(),
    class: "space-nowrap",
  },
  {
    key: "operator.name",
    sortable: true,
    label: i18n.t('tableReports.operator').toUpperCase(),
  },
  {
    key: "player.userId",
    sortable: true,
    label: i18n.t('tableReports.userId').toUpperCase(),
    class: "space-nowrap text-center",
  },
  {
    key: "player.username",
    sortable: true,
    label: i18n.t('betDetail.user').toUpperCase(),
  },
  {
    key: "platform",
    sortable: true,
    label: "Platform".toUpperCase(),
  },
  {
    key: "currency.short",
    sortable: true,
    label: i18n.t('tableReports.currency').toUpperCase(),
  },
  {
    key: "totalBet",
    sortable: true,
    label: i18n.t('betDetail.totalBet').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "totalWin",
    sortable: true,
    label: i18n.t('betDetail.totalWin').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "rateUSD",
    sortable: true,
    label: i18n.t('tableReports.rateUSD').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "betUSD",
    sortable: true,
    label: i18n.t('tableReports.betUSD').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "winUSD",
    sortable: true,
    label: i18n.t('tableReports.winUSD').toUpperCase(),
    class: "space-nowrap text-end",
  },
  {
    key: "details",
    sortable: true,
    label: i18n.t('tableReports.details').toUpperCase(),
  },
  {
    key: "createdAt",
    sortable: true,
    label: i18n.t("tableReports.date").toUpperCase(),
    class: "space-nowrap",
  },
]